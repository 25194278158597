import { configureStore, createSelector } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { authApi } from 'services/authApi';
import { api as baseApi } from 'services/baseApi';

import createReducer from './reducer';

const middlewares = [baseApi.middleware, authApi.middleware];

export const store = configureStore({
  reducer: createReducer(),
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export const { dispatch } = store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const createAppSelector = createSelector.withTypes<RootState>; //Todo to be uncomitted when rtk query will be implemented
