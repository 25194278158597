//TODO upgrade with RootState type
import { combineReducers } from '@reduxjs/toolkit';

import appReducer from 'app/containers/App/slice';
import authReducer from 'app/containers/Auth/authSlice';
import reducerTaskSetFilters from 'app/containers/TaskSets/TaskSetQuickFilters/sliceTaskSetFilters';
import { authApi } from 'services/authApi';
import { api as baseApi } from 'services/baseApi';

import { InjectedReducersType, StaticReducersType } from './storeTypes';

const staticReducers: StaticReducersType = {
  app: appReducer,
  auth: authReducer,
  authApi: authApi.reducer,
  taskSetFilters: reducerTaskSetFilters,
  api: baseApi.reducer,
};

const createReducer = (injectedReducers: InjectedReducersType = {}) => {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0 && Object.keys(staticReducers).length === 0) {
    return (state: any) => state;
  } else {
    return combineReducers({
      ...staticReducers,
      ...injectedReducers,
    });
  }
};

export default createReducer;
