import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { TaskSetFiltersState } from 'types';

export const initialState: TaskSetFiltersState = {
  planned: null,
  assigned: null,
  taskSetStatus: null,
  taskTypeId: null,
  companyIdsList: null,
  regionList: null,
};

const taskSetsSlice = createSlice({
  name: 'taskSetFilters',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<TaskSetFiltersState>) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = taskSetsSlice.actions;

export const { name: sliceKeyTaskSetFilters } = taskSetsSlice;

export default taskSetsSlice.reducer;
