import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';

import { RootState } from 'appStore/store';
import config from 'config';
import { GraphQLClient } from 'graphql-request';

export const client = new GraphQLClient(config.REACT_APP_GRAPHQL_API_BASE_URL ?? '');

const baseQuery = graphqlRequestBaseQuery({
  client,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
  customErrors: ({ response }) => response.errors,
});

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
});
