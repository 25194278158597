import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi } from 'services/authApi';

import { localStorageGet, localStorageRemove, localStorageSet } from 'utils/localStorage';

export interface AuthUser {
  id: number;
  name: string;
  roles: string[];
}

export interface AuthState {
  token: string | null;
  errorMessage: string | null;
  refreshError: boolean;
  sessionStartTime: string | null;
  refresh_token: string | null;
  user: AuthUser | null;
}

export const initialState: AuthState = {
  token: localStorageGet('token') ? JSON.parse(localStorageGet('token') as string) : null,
  user: localStorageGet('user') ? JSON.parse(localStorageGet('user') as string) : null,
  sessionStartTime: localStorageGet('sessionStartTime') ?? null,
  refresh_token: localStorageGet('refresh_token') ? JSON.parse(localStorageGet('refresh_token') as string) : null,
  errorMessage: null,
  refreshError: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearUserData(state) {
      state.user = null;
      state.token = null;
      state.refresh_token = null;
      state.sessionStartTime = null;
      localStorageRemove('user');
      localStorageRemove('token');
      localStorageRemove('refresh_token');
      localStorageRemove('sessionStartTime');
    },
    setSessionStartTime(state, action: PayloadAction<string | null>) {
      state.sessionStartTime = action.payload;
      localStorageSet('sessionStartTime', action.payload);
    },
    setErrorMessage(state, action: PayloadAction<string | null>) {
      state.errorMessage = action.payload;
    },
    removeData() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      const { user, token, refresh_token } = payload;
      state.token = token;
      localStorageSet('token', JSON.stringify(token));
      state.user = user;
      localStorageSet('user', JSON.stringify(user));
      state.refresh_token = refresh_token;
      localStorageSet('refresh_token', JSON.stringify(refresh_token));
    });
    builder.addMatcher(authApi.endpoints.refresh.matchFulfilled, (state, { payload }) => {
      const { user, token, refresh_token } = payload;
      state.token = token;
      localStorageSet('token', JSON.stringify(token));
      state.user = user;
      localStorageSet('user', JSON.stringify(user));
      state.refresh_token = refresh_token;
      localStorageSet('refresh_token', JSON.stringify(refresh_token));
    });
  },
});

export const { clearUserData, removeData, setErrorMessage, setSessionStartTime } = authSlice.actions;

export default authSlice.reducer;
