import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'appStore/store';
import config from 'config';

import { AuthUser } from 'app/containers/Auth/authSlice';

export interface UserResponse {
  user: AuthUser;
  token: string;
  refresh_token: string;
}

export interface LoginRequest {
  username: string;
  password: string;
}

export interface SetPasswordRequest {
  password: string;
  token: string;
}

interface SetPasswordResponse {
  success: boolean;
  error: string[];
}

export interface RefreshRequest {
  refresh_token: string;
}

const baseQuery = fetchBaseQuery({
  baseUrl: config.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery,
  endpoints: builder => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: credentials => ({
        url: '/token',
        method: 'POST',
        body: credentials,
      }),
    }),
    refresh: builder.mutation<UserResponse, RefreshRequest>({
      query: refreshRequest => ({
        url: '/token/refresh',
        method: 'POST',
        body: refreshRequest,
      }),
    }),
    setPassword: builder.mutation<SetPasswordResponse, SetPasswordRequest>({
      query: setPasswordRequest => ({
        url: '/users/set-password',
        method: 'POST',
        body: setPasswordRequest,
        headers: { Authorization: '' },
      }),
    }),
  }),
});

export const { useLoginMutation, useRefreshMutation, useSetPasswordMutation } = authApi;
