// The initial state of the App container
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AppState, Notification } from './types';

export const initialState: AppState = {
  notifications: null,
  focus: true,
  showSidebar: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    removeNotifications(state, action: PayloadAction<string | undefined>) {
      const data: Notification[] = [];
      if (!action.payload) {
        state.notifications = null;
        return;
      }
      if (state.notifications) {
        state.notifications.forEach(element => {
          if (element.id !== action.payload) {
            data.push(element);
          }
        });
      }
      state.notifications = data;
    },
    addNotifications(state, action: PayloadAction<Notification[]>) {
      let data: Notification[] = [];
      if (state.notifications) {
        data = Array.from(state.notifications);
      }
      data.push(...action.payload);
      state.notifications = data;
    },
    addNotification(state, action: PayloadAction<Notification>) {
      const data: Array<Notification> = [];
      if (state.notifications) {
        state.notifications.forEach(element => data.push(element));
      }
      data.push(action.payload);
      state.notifications = data;
    },
    changeFocus(state, action: PayloadAction<boolean>) {
      state.focus = action.payload;
    },
    toggleShowSidebar(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
  },
});

export const { addNotification, addNotifications, changeFocus, removeNotifications, toggleShowSidebar } =
  appSlice.actions;
export default appSlice.reducer;
